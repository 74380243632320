<template>
  <div>
    <c-table-study-plan
      :title="title"
      :items="getItems"
      :columns="columns"
      :totalRows="totalRows"
      :perPage="perPage"
      :currentPage="currentPage"
      :api="api"
      :apiDelete="apiDelete"
      :apiImport="apiImport"
      :import="actions.import"
      :export="actions.export"
      :exportFile="exportFile"
      @changeCurrentPage="changeCurrentPage"
      @changePerPage="changePerPage"
    ></c-table-study-plan>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import CTable from "@/components/CTable";
import CTableStudyPlan from '@/components/CTableStudyPlan';

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CTable,
    ToastificationContent,
    CTableStudyPlan,
  },
  data() {
    const permission = this.$store.getters["app/ACTIONS"];
    const { course, year, semester } = this.$route.params;

    return {
      title: "Detail Kartu Rencana Studi",
      api: `student-grades/list-krs-students/year/${year}/courseCode/${course}/semester/${semester}`,
      apiDelete: 'student-grades',
      apiImport: 'student-grades',
      dir: false,
      items: [],
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      actions: {
        import: false,
        export: Boolean(permission.export),
      },
      exportFile: "list-mahasiswa.xlsx",
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "nim", label: "nim", sortable: true },
        { key: "name", label: "nama mahasiswa", sortable: true },
        { key: "status", label: "status", sortable: true },
        {
          key: "action",
          label: "aksi",
          class: "text-center",
          actions: {
            show: Boolean(permission.show),
            edit: Boolean(permission.edit),
            destroy: Boolean(permission.destroy),
          },
        },
      ],
    };
  },
  mounted() {
    const _ = this;

    if (_.$route.params) {
      if (_.$route.params.variant) {
        _.toastNotification(
          _.$route.params.variant,
          "BellIcon",
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    toastNotification(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Pemberitahuan",
          icon,
          text,
          variant,
        },
      });
    },
  },
};
</script>
